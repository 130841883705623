import React, { useEffect, useState } from 'react';
import { transformUrl } from './helpers';
import './App.css';

function App() {
  const [isRedirecting, setIsRedirecting] = useState(false);
  useEffect(() => {
    setIsRedirecting(true);
    const url = window.location.href;
    const transformedUrl = transformUrl(url);
    window.location.replace(transformedUrl);
    setIsRedirecting(false);
  }, []);

  if (isRedirecting) {
    return null;
  }

  return (
    <div className="App">
      <header className="App-header">
        <img src="/logo.png" className="App-logo" alt="logo" />
      </header>
    </div>
  );
}

export default App;
